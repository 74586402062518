<template>
  <div>
    <div class="col-md-3">
      <div class="pm-card">
        <div class="col-md-12">
          <label>Selecione uma turma</label>
          <select
            class="p-inputtext p-component"
            style="appearance: revert !important; width: 100% !important;"
            :value="turmaId"
            v-model="turmaId"
            @change="listTblDados()"
          >
            <option :value="0" disabled selected>
              -- Escolha uma Turma --
            </option>
            <option v-for="turma in turmas" :key="turma.id" :value="turma.id">
              {{ turma.nome }} - {{ turma.escola.nome }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="pm-card">
        <div class="col-md-12">
          
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Turma Nome</th>
                <th>Disc Fantasia Nome</th>
                <th>Qdt Presenças</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="n in listarProfessor" :key="n">
                <td>{{ n.turma.nome }}</td>
                <td>{{ n.disc_fantasia_nome }}</td>
                <td>{{ n.qdt_presenca}}</td>
                <td>
                  <span class="badge badge-primary" style="background-color: #06d6a7;" v-if="n.status == '0'">Habilitado</span>
                  <span class="badge badge-secondary" style="background-color: #f63f0f;" v-else>Arquivado</span>
                </td>
                <td>
                  <button style="margin-right:5px;" class="btn btn-primary" :class="n.status ? 'disabled' : '' " @click="n.status ? not() : select = n; modalArquivar = true;" :title=" !n.status ? 'Cuidado ao Arquivar não poderar mais desfazer essa ação!' : ' Arquivado em '+  n.updated_at.split('T')[0].split('-').reverse().join('/') ">
                    <p style="margin-bottom: 0px !important;" v-if="!n.status">Arquivar</p>
                    <p style="margin-bottom: 0px !important;" v-else>Arquivado em {{ n.updated_at.split('T')[0].split('-').reverse().join('/') }}</p>
                  </button>
                  <button class="btn btn-primary"   :class="!n.status ? 'disabled' : '' " @click="!n.status ? not() : select = n; modalTransf = true; professoresFiltrados();" title="Transferir copias de presenças para outro professor">
                    Transferir copias de presenças
                  </button>
                  <button class="btn btn-primary"  :class="!n.status ? 'disabled' : '' "  @click="select = n; modalTransfD = true;" title="Transferir copias de presenças para outro disciplina">
                    Transferir copias de presenças p/disciplina
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <pm-Dialog
            header="Deseja Arquivar Presenças do professor?" v-model:visible="modalArquivar" :style="{width: '50vw'}" :maximizable="true" :modal="true">
            <p>Cuidado após Arquivar não poderar mais desfazer essa ação!</p>
            <div class="col-md-12">
              <button class="btn btn-primary"  @click="arquivar()" style="margin-right:5px;">
                sim
              </button>
              <button class="btn btn-danger"   @click="modalArquivar = false">
                não
              </button>
            </div>
          </pm-Dialog>

          <pm-Dialog
            header="Deseja Transferir um copia de presenças para outro professor?" v-model:visible="modalTransf" :style="{width: '50vw'}" :maximizable="true" :modal="true">
            <div class="col-md-12" style="margin-bottom: 20px">
              <label for="escola"> Selecione o Professor</label>
              <select
                class="p-inputtext p-component"
                v-model="professor"
                style="appearance: revert !important;width: 100% !important;"
              >
                <option v-for="n in this.listarProfessorCopy" :key="n" :value="n">
                  {{n.disc_fantasia_nome}} 
                </option>
              </select>
            </div>
 
            <div class="col-md-12" style="margin-bottom: 20px">
              <p>Por favor, selecione o intervalo de datas para o envio das presenças.</p>
              <div class="form-group">
                <input type="date" class="p-inputtext p-component" v-model="dateInicio">
                <b> até </b> 
                <input type="date" class="p-inputtext p-component" v-model="dateFinal">
              </div>
            </div>
            <div class="col-md-12" v-if="dateInicio && dateFinal && professor">
              <button class="btn btn-primary"  @click="transferirCopia()" style="margin-right:5px;">
                Transferir Copia
              </button>
            </div>
          </pm-Dialog>

          <pm-Dialog
            header="Deseja Transferir um copia de presenças para uma Disciplina?" v-model:visible="modalTransfD" :style="{width: '50vw'}" :maximizable="true" :modal="true">
            <div class="col-md-12" style="margin-bottom: 20px">
              <label for="escola"> Selecione a Disciplina</label>
              <select
                class="p-inputtext p-component"
                v-model="disciplina"
                style="appearance: revert !important;width: 100% !important;"
              >
                <option v-for="n in this.listarDisciplina" :key="n" :value="n.disciplina_id">
                  {{n.nome_disciplina}} 
                </option>
              </select>
            </div>
 
            <div class="col-md-12" style="margin-bottom: 20px">
              <p>Por favor, selecione o intervalo de datas para o envio das presenças.</p>
              <div class="form-group">
                <input type="date" class="p-inputtext p-component" v-model="dateInicio">
                <b> até </b> 
                <input type="date" class="p-inputtext p-component" v-model="dateFinal">
              </div>
            </div>
            <div class="col-md-12" v-if="dateInicio && dateFinal && disciplina">
              <button class="btn btn-primary"  @click="transferirCopiaDisciplina()" style="margin-right:5px;">
                Transferir Copia
              </button>
            </div>
          </pm-Dialog>

        </div>
      </div>  
    </div>
  </div>
</template>
<script>
  import { defineComponent } from "vue";
  import axios from 'axios';

  export default defineComponent({
    components: {
    },
    data() {
      return {
        modalArquivar:false,
        modalTransf:false,
        modalTransfD:false,
        select: {},
        turmaId: 0,
        turmas: [],
        listarProfessor: [],
        listarProfessorCopy: [],
        listarDisciplina: [],
        anoSelect: 2023,
        professor:[],
        disciplina: [],
        dateInicio: null,
        dateFinal: null,
      }
    },
    beforeMount() {
      this.getTurmaAll ();
    },
    methods: {
      async getTurmaAll () {
        this.anoSelect = sessionStorage.getItem("anoSelecionado");
        const res = await axios.get(`/${this.anoSelect}/turmas-sem-pag`)
        this.turmas = res.data;
      },

      professoresFiltrados() {
        this.listarProfessorCopy = [...this.listarProfessor]
        for (let i = this.listarProfessorCopy.length - 1; i >= 0; i--) {
          if (this.listarProfessorCopy[i].status === 1) {
            this.listarProfessorCopy.splice(i, 1);
          }
        }
        return this.listarProfessorCopy
      },

      async listTblDados(){
        this.anoSelect = sessionStorage.getItem("anoSelecionado");
        const res = await axios.post(`/prof-principals`,{
            'turma_id': this.turmaId,
            'ano': this.anoSelect, 
        })
        this.listarProfessor = res.data;

        const res2 = await axios.post(`/prof-principals-disc`,{
            'turma_id': this.turmaId,
            'ano': this.anoSelect, 
        })
        this.listarDisciplina = [];
        this.listarDisciplina = res2.data;
      },

      async arquivar(){
        await axios.delete(`/prof-principals/${this.select.id}`);
        this.modalArquivar = false;
        this.listTblDados();
      },

      async transferirCopia(){
        if(!this.dateInicio){
         return false
        }
        if(!this.dateFinal){
         return false
        }
        if(!this.professor.id){
         return false
        }
        await axios.post(`/prof-principals-criar-presenca`,{
          'data_inicial': this.dateInicio,
          'data_final': this.dateFinal,
          'turma_id': this.select.turma_id,
          'professor_id': this.select.professor_id,
          'disc_fantasia_id': this.select.disc_fantasia_id,
          'id': this.select.id,
          'id_professor_tipo_new': this.professor.id,
        })

        this.modalTransf = false;
        this.listTblDados();
      },

      async transferirCopiaDisciplina(){
        if(!this.dateInicio){
         return false
        }
        if(!this.dateFinal){
         return false
        }
        if(!this.disciplina){
         return false
        }
        await axios.post(`/prof-principals-criar-presenca-disc`,{
          'data_inicial': this.dateInicio,
          'data_final': this.dateFinal,
          'turma_id': this.select.turma_id,
          'id': this.select.id,
          'disciplina_id': this.disciplina,
        })

        this.modalTransfD = false;
        this.listTblDados();
      }
    }
  });
</script>
